/* You can add global styles to this file, and also import other style files */
// prettier-ignore
@import './styles/main.scss';

:root {
  --text-color-4: #fff;
}

html,
body {
  height: 100%;
}

button:focus {
  outline: none;
}

.font-weight-bold {
  font-weight: 800;
}

.no-cap-validator {
  cap-validator {
    display: none;
  }
}

app-page-layout-header.purchase-order-page-layout-header {
  .page__actions {
    display: flex;
    flex-direction: row-reverse;

    cap-action-menu {
      margin-left: 10px;
    }
  }
}

p-dynamicdialog {
  .p-dialog {
    &.order-picking-report-change {
      .p-dialog-header {
        display: none;
      }

      .p-dialog-content {
        padding: 0;
        overflow: hidden;

        .report-change-info-table {
          p-table {
            .cap-info-table {
              .p-datatable-wrapper {
                table {
                  thead {
                    @include media-breakpoint-767() {
                      display: table-header-group;
                    }

                    tr {
                      th {
                        background-color: transparent !important;
                        height: auto;
                        font-weight: 600;
                        color: $info-table-th-color;
                      }
                    }
                  }

                  tbody {
                    tr {
                      td {
                        cap-input-number {
                          .p-inputgroup {
                            input.p-inputtext[readonly] {
                              border: 1px solid $border-color;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.keyboard-container {
  background-color: $white;

  .hg-rows {
    .hg-row {
      .hg-button {
        background-color: $gray-light;
        color: $primary-lighter;
        width: 60px;
        height: 60px;
        font-size: 1rem;
        font-weight: 600;

        &.hg-button-bksp {
          width: 130px;
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

.keyboard-container, .keyboard-container .hg-button {
  cursor: pointer;
  touch-action: manipulation;
}

@media screen and (max-width: 450px) {
  .keyboard-container {
    .hg-rows {
      .hg-row {
        padding-top: 10px;
        .hg-button {
          height: 7vh;
          width: 13.5vw;
          border-radius: 3px;
          border-bottom-color: transparent;

          &.hg-button-bksp {
            width: 30vw;
          }
        }
      }
    }
  }
}
