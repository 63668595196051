@import 'abstracts';

$max-tablet-width: 1020px;

@mixin icon-style {
  color: $gray-dark;
  opacity: 1;
}

@mixin input-border {
  border: $filter-gray 2px solid;
}

body cpb-config-list {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: $max-tablet-width) {
  cpb-list-renderer.responsive-selectable-table.report-change {
    p-table table {
      thead tr th:first-child,
      tbody tr:not(.empty-message-row) td:first-child {
        display: none !important;
      }
      tbody tr td:last-child {
        border-right: 1px solid $border-color !important;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
    }
    th.th_allocated_and_quantity {
      width: 100px !important;
    }
  }
  @media screen and (max-width: 839.98px) {
    cap-dynamic-filters .cap-filters .filter-button > cap-button {
      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }
    }
  }

  cpb-list-renderer.responsive-selectable-table:not(.report-change):not(.order-picking) {
    p-table table {
      thead tr th:first-child,
      thead tr th:last-child,
      tbody tr:not(.empty-message-row) td:first-child,
      tbody tr:not(.empty-message-row) td:last-child {
        display: none !important;
      }
    }

    th.th_allocated_and_quantity {
      width: 35px !important;
    }
  }

  cpb-list-renderer.responsive-selectable-table {
    tbody tr td {
      height: 90px !important;
      border-top: 1px solid $border-color !important;
      border-bottom: 1px solid $border-color !important;

      &.cap-info-table__column-description_responsive {
        border-left: 1px solid $border-color !important;
        border-radius: 10px 0 0 10px;
      }

      &.cap-info-table__column-quantity_responsive {
        border-right: 1px solid $border-color !important;
        border-radius: 0 10px 10px 0;
      }

      &:last-child {
        font-size: 20px;
      }
    }

    .p-datatable-tbody tr.cap-table-row-selected > td {
      border-color: $primary-lighter !important;
      background-color: rgba($primary-lighter, 0.03);

      &:last-child {
        border-color: $primary-lighter !important;
      }
    }

    cpb-list-renderer-bulk-actions {
      display: none !important;
    }
  }
}

// Sticky table headers for desktop
@media screen and (min-width: 960px) and (orientation: landscape) {
  p-table:not(.no-sticky-header) {
    .cap-info-table {
      .p-datatable-wrapper {
        overflow: unset !important;
      }

      table {
        thead {
          tr th {
            z-index: 2;
            top: $nav-height !important;
            position: sticky !important;
            background-color: $gray-light !important;
          }
        }
      }
    }
  }

  p-tabpanel,
  p-dynamicdialog,
  app-panel {
    p-table .cap-info-table .p-datatable-wrapper table {
      thead {
        tr th {
          background-color: $white !important;
        }
      }
    }
  }

  p-dynamicdialog,
  app-panel {
    p-table .cap-info-table .p-datatable-wrapper table thead {
      tr th {
        top: 0 !important;
      }
    }
  }

  app-public-product-list-table {
    p-table {
      .cap-info-table table thead tr.table-header.sticky {
        z-index: 2;
        top: 130px !important;
        position: sticky !important;
        background-color: $gray-light !important;

        td {
          border-bottom: 1px solid $border-color;
          padding: 10px;
          font-weight: bold;
        }
      }
    }
  }
}

// @TODO: Just a temporary solution, as we need to update capturum/builders package, the issue was there
app-batch-invoice-dialog,
cap-login {
  cap-validator {
    display: block !important; /* stylelint-disable-line declaration-no-important */
  }
}

body .p-inputtext {
  padding: 0.5rem;
}

cpb-list-renderer {
  .header {
    background: none;
    box-shadow: none;
  }
}

body cap-checkbox-filter {
  @include media-breakpoint-down(md) {
    .filter-icon i {
      font-size: 0.675rem;
    }
  }
}

.cap-dynamic-filter-layout {
  @include media-breakpoint-down(md) {
    .delete-filter {
      i {
        padding: 7px;
      }
    }
  }
}

cap-dynamic-filters {
  .cap-filters {
    justify-content: space-between;

    .filter-button {
      cap-button {
        height: 33px;

        button.tertiary {
          background-color: $filter-gray;
          border-radius: 17px;
          height: 33px;
          color: $body-color;
          font-size: 15px !important; /* stylelint-disable-line declaration-no-important */
          text-decoration: none;
          border: none;

          .p-button-label {
            color: $column-text-color;
            font-weight: 600;
          }
        }
      }
    }
  }
}

body .pi {
  font-size: 0.7rem !important; /* stylelint-disable-line declaration-no-important */
  margin-right: 3px;
}

body cpb-list-renderer {
  cap-validator {
    display: none;
  }

  .row-action__items li .row-action__icon {
    flex: 0 0 15px !important; /* stylelint-disable-line declaration-no-important */
    max-width: 15px !important; /* stylelint-disable-line declaration-no-important */
    font-size: 1rem;
    z-index: 1 !important;
  }

  cpb-list-renderer-filters .table-filters .filters cap-dynamic-filters .filter {
    .filter-icon {
      opacity: 1;

      i {
        color: $gray-dark;
      }
    }

    ::placeholder,
    .p-placeholder {
      color: $filter-font-color;
    }
  }

  &:nth-child(1n) {
    .row-action__icon {
      width: 20px;
    }

    .header,
    .cap-info-table.p-datatable {
      table {
        border-spacing: 0 8px;
        font-size: 0.85rem;
      }

      .p-datatable-thead th {
        background: transparent;
        position: initial;
        min-width: 100px;
        color: $column-text-color;
        font-family: $font-semi-bold;

        span {
          color: $column-text-color;
        }
      }
    }

    .cap-info-table.p-datatable .p-datatable-thead th {
      @include textEllipsis();
    }

    .header {
      position: initial;
    }

    .header--sticky {
      position: unset;
    }

    div.date-filter .cap-calendar.p-calendar.cap-rounded input,
    div.date-filter .cap-calendar.p-calendar.cap-rounded input:hover,
    div.date-filter .cap-calendar.p-calendar.cap-rounded input:enabled:hover:not(.p-state-error) {
      outline: none;
      border-radius: 20px;
      padding-left: 36px;
      left: 0;
      width: 100%;
    }

    .actions cap-button p-button button:not(:disabled) {
      background: $filter-gray;
      border-radius: 17px;
      height: 33px;
      color: $gray-dark;
      font-size: 15px;
      text-decoration: none;
      border: none;
      font-weight: 600;
    }

    div {
      .text-filter cap-input .form-element .p-inputgroup input,
      .text-filter cap-input .form-element .p-inputgroup input :hover,
      .text-filter cap-input .form-element .p-inputgroup input:enabled:hover:not(.p-state-error),
      .text-filter cap-input .form-element .p-inputgroup input:enabled:focus:not(.p-state-error) {
        @include input-border;
        @include setPlaceholderStyles;
        outline: none;
        min-height: 33px;
      }

      .text-filter cap-input .form-element .p-inputgroup input.p-inputtext,
      .text-filter cap-input .form-element .p-inputgroup input.p-inputtext:hover,
      .text-filter cap-input .form-element .p-inputgroup input.p-inputtext:focus,
      .text-filter cap-input .form-element .p-inputgroup input.p-inputtext:enabled:hover:not(.p-state-error),
      .text-filter cap-input .form-element .p-inputgroup input.p-inputtext:enabled:focus:not(.p-state-error),
      .text-filter cap-input .form-element .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error).cap-rounded {
        @include input-border;
        outline: none;
        min-height: 33px;
        font-size: unset;
      }
    }

    cap-autocomplete .form-element {
      @include input-border;
    }

    .cap-dropdown.p-dropdown.cap-rounded,
    .cap-multi-select.p-multiselect.cap-rounded {
      &,
      &.p-dropdown:not(.p-disabled):hover,
      &.p-dropdown:not(.p-disabled).p-focus,
      &.p-multiselect:not(.p-disabled):hover,
      &.p-multiselect:not(.p-disabled).p-focus {
        @include input-border;
        font-size: $cap-input-font;
        border-radius: 20px;
        color: $filter-font-color;
        font-weight: 600;
        height: $filter-height;

        .p-dropdown-label,
        .p-multiselect-label {
          height: 30px !important;
        }

        .p-multiselect-trigger {
          color: $filter-icon-color;
        }
      }
    }

    cap-dropdown.has-icon .p-dropdown .p-dropdown-label {
      padding-top: 5px;
      padding-left: 40px;
      color: $filter-font-color;
    }

    cap-dropdown .form-element p-dropdown .p-dropdown span {
      background: none;
    }

    .cap-dropdown.p-dropdown .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext,
    .cap-multi-select.p-multiselect
      .p-multiselect-panel
      .p-multiselect-header
      .p-multiselect-filter-container
      .p-inputtext {
      outline: none;
    }

    .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
      @include icon-style;
    }
  }

  .cpb-filter-height-3 .p-datatable-thead cap-info-table-header > tr > th {
    position: relative;
  }

  .fa-ellipsis-v {
    color: var(--cap-primary-color);
  }

  .table-filters-active-label {
    line-height: 25px;
  }
}

.filter-icon i {
  @include icon-style;
}

.p-multiselect-filter-icon {
  @include icon-style;
}

.cap-calendar.p-calendar.cap-rounded:hover input,
.cap-calendar.p-calendar.cap-rounded:hover input:enabled:hover:not(.p-state-error),
.cap-calendar.p-calendar.cap-rounded input,
.cap-calendar.p-calendar.cap-rounded input:enabled:hover:not(.p-state-error) {
  @include input-border;
  @include setPlaceholderStyles;
}

cpb-list-renderer.list_user-activity {
  .cap-info-table.p-datatable .p-datatable-thead {
    th:nth-child(5) {
      width: 45%;
    }
  }

  .p-datatable-tbody tr td {
    &:last-child {
      .cap-cell-tooltip {
        white-space: normal;
      }
    }
  }
}

body cap-info-table.p-datatable .p-datatable-tbody td {
  font-family: $font-regular;
}

.data-cell,
.cap-info-table.p-datatable .p-datatable-tbody td,
.cap-info-table.p-datatable .p-datatable-thead th {
  padding-left: 1px;
}

app-dynamic-component {
  cpb-list-renderer.list_stock-purchase-rows app-list-renderer-widget-text-style-by-condition .cap-cell-tooltip {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
}

// stylelint-disable-next-line selector-class-pattern
cpb-list-renderer thead cap-info-table-header tr th.th_d-none {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

app-public-order-product {
  cpb-list-renderer thead cap-info-table-header tr th.th_full-width,
  cpb-list-renderer .p-datatable-tbody tr td.full-width {
    @include media-breakpoint-down(xl) {
      width: 270px;
    }

    @include media-breakpoint-down(lg) {
      width: 200px;
    }
  }
}

app-sales-order-product-add {
  cpb-list-renderer thead cap-info-table-header tr th.th_full-width,
  cpb-list-renderer .p-datatable-tbody tr td.full-width {
    @include media-breakpoint-up(md) {
      width: 55%;
    }
  }

  @include media-breakpoint-down(md) {
    cpb-list-renderer cpb-list-renderer-filters .table-filters .table-filters-active {
      display: none;
    }
  }
}

// stylelint-disable-next-line selector-class-pattern
cpb-list-renderer thead cap-info-table-header tr th.th_full-width,
cpb-list-renderer .p-datatable-tbody tr td.full-width {
  @include media-breakpoint-up(xs) {
    width: 100px;
  }

  @include media-breakpoint-up(sm) {
    width: 150px;
  }

  @include media-breakpoint-up(md) {
    width: 250px;
  }

  @include media-breakpoint-up(lg) {
    width: 30%;
  }
}

.cap-info-table.p-datatable .p-datatable-tbody td {
  .cap-cell-tooltip {
    line-height: 1.1;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  &:not(.selectable-column):not(.reorderable-row).full-width {
    white-space: normal;
  }
}

app-sales-order-view {
  @media screen and (max-width: 960px) {
    formly-field {
      .label-wrapper,
      .field-wrapper {
        font-weight: 600;
        font-size: 15px !important;
        flex: 1 1 50% !important;
      }
    }
  }
}

// app-some-otoher-component-with-colums-on-mobile,
app-public-product-list-table,
app-sales-order-creditnote-dialog,
app-invoice-dialog,
app-sales-order-product-add,
app-stock-count,
app-sales-order-products,
app-sales-order-list {
  @media screen and (max-width: 960px) {
    .cap-info-table.p-datatable {
      .p-datatable-thead {
        display: contents;

        th.p-element.cap-info-table__header {
          padding-left: 5px;
        }
      }

      .p-datatable-tbody {
        tr.p-element.cap-info-table__row {
          display: revert;
          flex-direction: initial;
          box-shadow: none;
          font-weight: 600;
          font-size: 15px;

          input.p-inputtext.p-component.p-element {
            font-weight: 600;
            font-size: 15px;
          }

          td.p-element.cap-info-table__column {
            padding-left: 5px;

            &.cap-info-table__column-original_customer_name {
              padding-left: 10px;
            }

            cap-cell-data {
              span {
                font-size: 15px;
              }
            }
          }

          &.text-center {
            display: flex;
            padding: 15px;
            width: calc(100vw - 45px);

            td:first-child {
              display: contents;
            }
          }
        }

        tr:not(.text-center) {
          td {
            .cap-cell-tooltip-container span {
              white-space: initial;
            }

            .cap-info-table__mobile-header {
              display: none;
            }

            &.cap-info-table__column-actions {
              display: table-cell;
            }

            &:first-child {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  app-sales-order-list {
    @include showOnlyColumnsMobile(true, 2, 4, 5, 14);
  }

  app-sales-order-creditnote-dialog,
  app-invoice-dialog {
    @include showOnlyColumnsMobile(true, 1, 2, 3, 4, 5);
  }

  app-order-picking-overview,
  app-wms-product-picker {
    .p-datatable-tbody > tr {
      display: table-row !important;

      & > td {
        display: table-cell !important;
      }
    }
  }

  app-sales-order-products {
    @include media-breakpoint-down(sm) {
      .p-datatable > .p-datatable-wrapper {
        overflow: hidden;
      }
    }

    .p-datatable-thead th:first-child {
      width: 40%;

      @include media-breakpoint-down(sm) {
        width: 22%;
      }
    }
  }

  app-sales-order-products {
    @include media-breakpoint-down(xl) {
      .cap-info-table.p-datatable .p-datatable-tbody tr:not(.text-center) td:first-child {
        display: table-cell !important; /* stylelint-disable-line declaration-no-important */
      }
    }

    @include media-breakpoint-down(md) {
      .cap-info-table.p-datatable .p-datatable-tbody tr:not(.text-center) td:first-child {
        display: none !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }

  app-sales-order-product-add {
    @include showOnlyColumnsMobile(true, 1, 3, 4, 5, 6);
  }

  app-stock-count {
    .p-datatable-thead th:first-child {
      width: 70%;
    }
  }
}

@include media-breakpoint-up(sm) {
  .small-input .form-element {
    max-width: 200px;
  }
}

@include media-breakpoint-down(md) {
  app-sales-order-products app-tab-layout-header {
    cap-button:not(.add-product) {
      display: none;
    }
  }
}

cap-input.has-icon .p-inputgroup input.p-inputtext,
cap-input.has-icon .p-inputgroup input.p-inputtext:enabled:focus:not(.p-state-error),
cap-input.has-icon .p-inputgroup input.p-inputtext:enabled:hover:not(.p-state-error) {
  padding-left: 36px;
}

cpb-list-renderer,
app-table-filter {
  .cap-dropdown-panel.p-dropdown-panel,
  .cap-multi-select-panel,
  .p-datepicker:not(.p-datepicker-inline) {
    // Important is needed because it sets a style on the element itself
    z-index: 3 !important; /* stylelint-disable-line declaration-no-important */
  }
}

// 3-dot menu action in dialog
// TODO: remove this one when https://capturum.atlassian.net/browse/EMC-1077 is done
cpb-list-renderer cap-cta-button {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.table-filters-items .table-filter-row .filter .filter-icon {
  transform: translateY(calc(-50%)) !important; /* stylelint-disable-line declaration-no-important */
}

body {
  app-public-product-list,
  app-public-place-order,
  app-public-order-product {
    .cap-info-table.p-datatable .p-datatable-thead th span {
      font-size: 1rem;
      line-height: 1.2;
    }

    .p-component {
      font-size: 1rem;
      line-height: 1.2;
    }

    cpb-list-renderer:nth-child(1n) div .text-filter cap-input .form-element .p-inputgroup input.p-inputtext,
    cpb-list-renderer:nth-child(1n) div .text-filter cap-input .form-element .p-inputgroup input.p-inputtext:hover,
    cpb-list-renderer:nth-child(1n) div .text-filter cap-input .form-element .p-inputgroup input.p-inputtext:focus,
    cpb-list-renderer:nth-child(1n)
      div
      .text-filter
      cap-input
      .form-element
      .p-inputgroup
      input.p-inputtext:enabled:hover:not(.p-state-error),
    cpb-list-renderer:nth-child(1n)
      div
      .text-filter
      cap-input
      .form-element
      .p-inputgroup
      input.p-inputtext:enabled:focus:not(.p-state-error),
    cpb-list-renderer:nth-child(1n)
      div
      .text-filter
      cap-input
      .form-element
      .p-inputgroup
      .p-inputtext:enabled:focus:not(.p-state-error).cap-rounded {
      font-size: 1rem;
      line-height: 1.2;
    }
  }

  app-sales-order-product-add app-list-renderer-widget-unit .p-inputgroup input.p-inputtext[disabled] {
    border-color: transparent;
  }
}

//special for app-sales-order-product-add
.hidden {
  @media screen and (max-width: 960px) {
    @include showOnlyColumnsMobile(true, 3, 4);
  }

  @include media-breakpoint-down(md) {
    .p-datatable-thead th:first-child {
      width: 50%;
    }
  }

  @include media-breakpoint-down(sm) {
    .cap-info-table.p-datatable .p-datatable-thead th:nth-child(4) {
      //important because it needs to overwrite the mixin style
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }

    .cap-info-table.p-datatable .p-datatable-tbody tr td:nth-child(4) {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }

    .p-datatable-thead th:first-child {
      width: 50%;
    }
  }
}

app-sales-order-products {
  @include media-breakpoint-down(sm) {
    .cap-info-table.p-datatable .p-datatable-thead th,
    .cap-info-table.p-datatable .p-datatable-tbody td {
      padding-left: 1px;
      padding-right: 3px;
    }

    .p-inputgroup input.p-inputtext {
      padding-left: 4px;
    }

    .cap-info-table.p-datatable .p-datatable-thead th:nth-child(2) {
      width: 10%;
    }

    .cap-info-table.p-datatable .p-datatable-thead th:nth-child(3) {
      width: 20%;
    }

    .cap-info-table.p-datatable .p-datatable-thead th:nth-child(4) {
      width: 23%;
    }
  }
}

app-sales-order-products,
app-sales-order-product-add {
  @include media-breakpoint-down(sm) {
    .tab__header {
      padding-bottom: 5px;
    }

    .table-filters.cap-table-filter {
      padding-bottom: 0;
    }

    .cap-info-table.p-datatable .p-datatable-tbody tr {
      margin-bottom: 3px;
    }
  }
}

app-purchase-order-list {
  .purchase-order-grouped .cap-info-table.p-datatable .p-datatable-thead {
    th:nth-child(6) {
      width: 12%;
    }

    th:last-child {
      width: 60px;
    }
  }
}

app-sales-order-list {
  @include media-breakpoint-up(sm) {
    .cap-info-table.p-datatable .p-datatable-thead {
      th:nth-child(11) {
        width: 10%;
      }

      th:last-child {
        width: 60px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .table-filters {
      padding-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
    }

    .cap-info-table.p-datatable {
      .p-datatable-thead {
        th:nth-child(2) {
          width: 27%;
        }

        th:nth-child(4) {
          width: 45%;
        }

        th:last-child {
          width: 8%;
        }
      }

      .p-datatable-tbody {
        td:last-child {
          padding: 0 !important; /* stylelint-disable-line declaration-no-important */
        }
      }

      table {
        border-spacing: 0 4px !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }
}

body {
  @include media-breakpoint-down(md) {
    cpb-list-renderer.wrap-on-mobile .cpb-list-renderer cap-info-table p-table .p-datatable.p-component table {
      background-color: $white;

      thead {
        display: none;
      }

      tbody cap-info-table-row {
        &:not(:first-child) {
          tr {
            border-top: 1px solid $border-color;

            .cap-info-table__column-product.description,
            .full-width {
              margin-top: 10px;
            }
          }
        }

        tr.p-element.cap-info-table__row {
          display: flex !important; /* stylelint-disable-line declaration-no-important */
          flex-flow: wrap !important; /* stylelint-disable-line declaration-no-important */
          width: calc(100vw - calc($mobile-page-gutters) - 25px);

          .cap-info-table__column-product.description,
          .full-width {
            flex: 1;
            min-width: 100%;
            height: auto;
          }

          td {
            flex: 2;
            padding-left: 5px;
            padding-right: 5px;

            &.d-none {
              display: none !important; /* stylelint-disable-line declaration-no-important */
            }

            &:nth-child(3),
            &.cap-info-table__column-actions {
              width: 30px;
              max-width: 30px;
            }
          }
        }
      }
    }

    app-sales-order-product-add,
    app-stock-count {
      cap-info-table-row {
        tr.p-element.cap-info-table__row {
          width: calc(
            100vw - calc($mobile-page-gutters)
          ) !important; /* stylelint-disable-line declaration-no-important */
        }

        .cap-info-table__mobile-cell {
          width: 100%;
        }
      }
    }

    cap-dynamic-filters .cap-filters .filter-button .cap-button.primary:not(:disabled) {
      background-color: var(--cap-primary-color);
      color: $white;
    }
  }
}

body {
  @include media-breakpoint-up(md) {
    cpb-list-renderer {
      th.th_price-input {
        width: 102px;
      }

      th.th_quantity {
        width: 70px;
      }
    }

    cpb-list-renderer.opened-panel {
      th.th_full-width {
        width: 100px;
      }
    }
  }
}

cpb-list-renderer.list_stock-locations {
  .cap-info-table.p-datatable .p-datatable-thead th {
    &:first-child,
    &:nth-child(2) {
      width: 17%;
    }

    &:nth-child(3) {
      width: 43%;
    }
  }
}

app-transport-list {
  .cap-info-table.p-datatable .p-datatable-tbody td {
    height: 15px;

    .row-action > i {
      padding: 5px 15px;
    }
  }
}

.product-list-renderer {
  .content {
    height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  cpb-list-renderer-paginator {
    display: none;
  }
}

cpb-list-renderer {
  cap-dynamic-filters .filter-sidebar .cap-filters {
    label {
      display: none;
    }

    .filter-list .filter.search {
      display: none;
    }
  }

  .sidebar-filter-buttons {
    cap-button:first-child {
      display: none;
    }

    .sidebar-filter-right-buttons {
      width: 100%;

      cap-button {
        display: flex;
      }

      .cap-button.primary:not(:disabled) {
        background: $primary;
        color: $primary-light;
        width: 180px;
      }

      .cap-button.secondary:not(:disabled) {
        background-color: $filter-gray;
        border-radius: 17px;
        height: 33px;
        color: $body-color;
        width: 180px;
        padding: 0 20px;
      }
    }
  }
}
